.MerchantGeneralTabContainer {
  padding-top: 3%;
  padding-left: 2%;
  padding-right: 2%;
}

.SectionTitle {
  font-weight: 800 !important;
  margin-top: 5% !important;
}

.CardRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 2%;
}

.Width100 {
  width: 100%;
}

.Width45 {
  width: 45%;
}
.AccordianHeaderStyle {
  flex-direction: row;
  width: 100%;
  display: flex;
}
.EditButtonStyle {
  padding: 0 !important;
  font-size: smaller;
}

.EditButtonBoxStyle {
  width: 100%;
  display: inline-flex;
  justify-content: end;
}
.SectoinStyle {
  background-color: #f7f7f7 !important;
  box-shadow: none !important;
  border-radius: 15px !important;
  margin-bottom: 2%;
  padding: 3%;
}
