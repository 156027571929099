.SettingBlockMainStyle {
  width: 100%;
  height: auto;
  margin-top: 5%;
}

.SettingBlocktitleStyle {
  font-size: 20px !important;
  font-weight: 500;
  color: #222222;
}
