.TransactionBottomBarContainer {
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  background: #fff;
  height: 75px;
  box-shadow: 0px 2px 10px rgba(58, 89, 124, 0.2);
  text-align: end;
  justify-content: end;
  padding: 20px 0px 20px 20px;
}

.TransactionButtonStyle {
  border-radius: 10px !important;
  width: 50%;
  text-transform: uppercase !important;
}

.TransactionResetButtonStyle {
  border-radius: 10px !important;
  margin-right: 2% !important;
  width: 50%;
  text-transform: uppercase !important;
}

.TransactionPrintButtonStyle {
  border-radius: 10px !important;
  margin-right: 2% !important;
  width: 50%;
  text-transform: uppercase !important;
}

.NewTransactionButtonStyle {
  border-radius: 10px !important;
  width: 100%;
  text-transform: uppercase !important;
}

.SpinnerStyle {
  margin-right: 1rem !important;
}

.BottomButtonContainerStyle {
  display: inline-flex;
  width: 29%;
  text-align: center;
  justify-content: center;
  margin-right: 15px !important;
}
