.TerminalContainerStyle {
  padding: 20px;
  width: 100%;
  height: 100%;
  min-width: 700px !important;
  
}

.TerminalInputBoxStyle {
  width: 70%;
}

.TerminalDisplayBoxStyle {
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  width: 30%;
  height: 100% !important;
  min-height: 710px !important;
  min-width: 300px;
  margin-left: 10px;
  background-color: #ffffff;

}

.MarginTop {
  width: 100%;
  height: 85px;
  margin-top: 0.2rem;
}

.NoPadding {
  padding: 0 !important;
}

.VirtualTerminalPageContainer {
  width: 100%;
  height: calc( 100vh - 130px ) !important;
  overflow: auto !important;
  box-sizing: border-box;
}
