.MoneyInputBoxStyle {
  padding: 20px 0px 20px 0px;
  width: 100%;
}

.CurrencyLabelStyle {
  border: unset !important;
  border-radius: 10px !important;
  padding: 0;
  background-color: transparent !important;
  height: 70px;
  align-items: flex-end !important;
  -webkit-align-items: end !important;
}

.MoneyInputTextFieldStyle {
  .MuiSvgIcon-root {
    top: unset !important;
  }
  background: #ffffff;
}

.CurrencyLabelStyle:before {
  display: none;
}

.MuiSelect-root {
  &:before {
    border: unset !important;
  }
}

.MoneyInputTextFieldStyle {
  padding: 0;
  width: 48%;
  font-size: larger;
}

#CurrencyLabel {
  padding: 0px 32px 0px 0px !important;
}

#Amount {
  height: 40px;
}

.MoneyInputEndormentStyle {
  font-size: 30px;
}
