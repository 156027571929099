.HostContainerStyle {
  padding: 20px;
}

.MarginTop {
  width: 100%;
  height: 85px;
  margin-top: 0.2rem;
}

.FormFieldLabelStyle {
  width: 100%;
}

.LeftControlStyle {
  width: 96%;
  margin-right: 2% !important;
  background: #ffffff;
}

.RightControlStyle {
  width: 100%;
  background: #ffffff;
}

.SingleControlStyle {
  width: 48%;
  margin-right: 2% !important;
  background: #ffffff;
}

.TitleStyle {
  margin-bottom: 1rem !important;
  font-weight: 600 !important;
}

.StatusBoxStyle {
  margin-bottom: 2rem !important;
  padding-bottom: 0.8rem !important;
  border-bottom: 1px solid #7c919d;
  width: 48%;
}
