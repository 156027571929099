.AccountPageStyle {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  margin-bottom: 5rem;
}

.PageHeaderStyle {
  display: inline-flex;
  width: 100%;
}

.SettingsTabPanelStyle {
  margin-top: 2%;
  margin-bottom: 6%;
  overflow: auto;
  height: calc(100vh - 50px);
}
