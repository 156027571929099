.ReportingPageStyle {
  
  padding-left: 1rem;
  padding-right: 1rem;
}

.DownloadButtonBoxStyle {
  justify-self: end;
  text-align: end;
  width: 100%;
}


.PageHeaderStyle {
  display: inline-flex;
  width: 100%;
  height: 40px;
  margin-top: 20px;
}

.ReportingMainPageDisplayArea {
  width: 100%;
}

.ReportingMainPageItemRowContent {
  width: 100%;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.ReportingMainPageItemRowBottomContent {
  width: 100%;
  margin-top: 20px !important;
  padding-bottom: 0px !important;

}

.TabsContainer {
  border-bottom: 1px; 
  border-color: 'divider';
  margin-top: 20px;
  margin-bottom: 20px;

}


.TransactionSearchBox {
  background-color: white;
  width: 98%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 10px !important;
}

.TransactionSearchBox .MuiOutlinedInput-root {
  border-radius: 10px;
}



.LayoutStyledMainWrapper {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.LayoutStyledMainWrapperTerminalTab {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: fixed;
  z-index: 2;
  width: 100%;
}

.ToolBarMain { 
  overflow: hidden;
  max-height: 55px !important;
  min-height: 55px !important; 
  height: 55px !important;
  min-width: 360px !important;

}

.TopBarName {
  background-color: white !important;
  min-height: 60px;
  padding-left: 20px;
  padding-right: 20px;
}

.TopBarForMobile {
  background-color: white !important;
  padding: 0 !important;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.AppBarClass {
  width: 100%;
  box-shadow: inset !important;
}

.NavLinks {
  width: 100%;
}

.logo {
  cursor: pointer;
}

.RightMenus {
  justify-self: end;
  text-align: end;
  width: 100%;
  display: inline-flex;
}

.RightMenuIcons {
  height: 1.5em;
  margin-top: 8px;
  margin-right: 30px;
  cursor: pointer;
}

.LinkStyle {
  text-decoration: none;
  color: #252424;
}

.FirstLink {
  margin-left: 70px;
}

.LogoutLink {
  margin-left: 20px;
}

.Profile-text {
  font-weight: 100;
  font-size: 16;
  color: #252424;
}

.Profile-menu {
  width: 50;
}

.TabNames {
  color: #575353;
  font-size: 14px;
  font-weight: 100 !important;
}

.TabNamesOnActive {
  color: #4f6ff0;
  font-size: 14px;
  font-weight: 100 !important;
}

.MobileHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.NavigationItem {
  text-transform: none !important;
  text-decoration: none !important;
  line-height: 0 !important;
}

.footerClass {
  text-align: end;
  margin: 15px;
}

.MuiAccordionSummary-expandIconWrapper {
  color: #2a50ee !important;
}

.TabStyle {
  padding: 0 !important;
  text-transform: none !important;
  text-align: left !important;
  align-items: unset !important;
  font-weight: 500 !important;
  min-width: fit-content !important;
  margin-right: 1rem !important;
}

.TabsStyle {
  width: 100%;
}

.DisplayNone {
  display: none !important;
}

.SideRailTitleStyle {
  text-transform: capitalize;
}

.DownloadIconStyle {
  margin-right: 2px;
  padding: 0;
}


.DownloadButtonStyle {
  font-weight: bold !important;
  text-transform: initial !important;
  height: 35px;
  max-width: 170px;
}
