.FirstNameStyle {
  width: 97% !important;
  background: #ffffff;
}

.SwitchControlField {
  margin-bottom: 2rem !important;
}

.DropdownFieldLabel {
  margin-left: 0.8rem !important;
}

.UserRow {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  height: 5.5rem;
}

.MultiFactor {
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
}

.TimeZone {
  margin-left: 1rem !important;
}

.SwitchFieldLabelStyle {
  font-size: small !important;
}

.SwitchFieldSecondStyle {
  width: 95% !important;
  margin-left: 2% !important;
  border-bottom: groove !important;
  margin-bottom: 1.5rem !important;
}

.HeadingText {
  width: 100% !important;
  margin-bottom: 0.5rem !important;
}

.SubHeadingText {
  width: 100% !important;
  margin-bottom: 1rem !important;
  font-weight: 500 !important;
}

.TopMargin {
  margin-top: 0.5rem !important;
}

.LastNameStyle {
  width: 97% !important;
  margin-left: 3% !important;
  margin-right: 4% !important;
  background: #ffffff;
}

.SectionBox {
  width: 96%;
}

.StyledButton {
  display: flex;
  justify-content: flex-end;
  height: 30px !important;
  margin-bottom: 10px !important;
  margin-right: 2% !important;
}

.StyledAddPhoneButton {
  display: flex;
  justify-content: flex-end;
  height: 30px !important;
  margin-right: 2% !important;
}

.StyledAddRoleButton {
  width: 100% !important;
  display: flex;
  justify-content: flex-end;
  height: 30px !important;
  margin-right: 2% !important;
}

.FullWidth {
  width: 100% !important;
}

.RoleSection {
  width: 100% !important;
  display: flex;
}

.ButtonText {
  text-transform: none !important;
  font-size: medium !important;
  height: 30px !important;
}

.RoleMarginTop {
  width: 100% !important;
  height: 85px;
  margin-top: 0.2rem !important;
}

.RoleFieldSection {
  display: flex;
  margin-right: 2% !important;
  width: 50%;
  flex-direction: column;
}

.SearchFieldLabelStyle {
  margin-top: 4% !important;
  width: 93% !important;
  margin-left: 4% !important;
  padding: 0px !important;
}

.SearchBox {
  margin-bottom: 10px !important;
}

.RoleAccountFieldSection {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.RoleDeviderStyle {
  margin-top: 7% !important;
}

.FormHelperTextStyle {
  margin-left: 2% !important;
  color: #d32f2f !important;
}
.FormHelperTextStyleLastName {
  margin-left: 5.5% !important;
  color: #d32f2f !important;
}

.ValueLabel {
  font-weight: 400;
  font-size: 16px;
}

.ValueStatusStyle {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #6fbe8f;
  margin-bottom: 1.5rem !important;
}
