.MerchantApiTabContainer {
  padding-top: 3%;
  padding-left: 2%;
  padding-right: 2%;
}

.ProfileTabBoxStyle {
  background-color: white;
  border-radius: 20px;
  margin-left: 25%;
  margin-right: 25%;
  padding: 1%;
  margin-bottom: 15%;
  width: 50%;
}

.ViewProfileBoxStyle {
  background-color: #f7f7f7;
  border-radius: 20px;
  padding: 3% 3% 5% 3%;
  margin-bottom: 2%;
}

.HeadertextStyle {
  font-weight: bold !important;
  font-size: 20px;
}

.ApikeyContentBoxStyle {
  margin-top: 20px;
}

.StatusContentBoxStyle {
  margin-top: 20px;
  width: 50%;
}

.LabelTextStyle {
  font-size: 14px !important;
  color: #7c919d;
}

.NameValueTextStyle {
  font-size: 48px !important;
}

.ValueTextStyle {
  font-size: 16px !important;
  padding: 10px 0px 10px 0px;
}

.ViewBoxStyle {
  margin: 20px 0px 20px 0px;
  border-bottom: 1px solid #7c919d;
}

.ProfEditButtonAlign {
  text-align: end;
  margin-bottom: 5%;
  margin-top: 10%;
}

.DynamicValueTextStyle {
  font-size: 16px !important;
  padding: 0px 0px 5px 0px;
}

.MarginTop {
  width: 100%;
  height: 85px;
  margin-top: 1rem;
}

.ControlStyle {
  width: 48%;
  margin-right: 2% !important;
}

.LastNameControlStyle {
  width: 50%;
}

.AccountInfoStyle {
  border-bottom: 1px solid #7c919d;
  padding-bottom: 20px;
}

.ContactInfoStyle {
  padding-top: 20px;
}

.EditValueTextStyle {
  border-bottom: 0.5px solid #7c919d;
  padding-bottom: 20px;
  padding-top: 20px;
}

.EditBoxStyle {
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid #7c919d;
}

.TimeZoneMarginTop {
  margin-top: 0.85rem !important;
}
