$small: 640px;

.App {
  background-color: rgb(242, 246, 249);
  min-height: 100vh;
}

.StyledSigninWrapper {
  background-color: #ffffff;
  box-shadow: 1px 1px 1px 1px lightgrey;
  box-sizing: border-box;
  min-width: 450px;
  padding: 3em;
  min-height: 450px;

  @media screen and (max-width: $small) {
    min-height: 100vh;
    box-shadow: none;
    width: auto;
    min-width: 100%;
    padding: 10em 3em;
  }
}

.SignInBox {
  margin-top: 2em;
}

.TwofactAuthBox {
  margin-top: 1.5em;
}

.PasswordBox {
  margin-top: 0.85em;
}

.UserNameBox {
  margin-top: 1em;
}

.LoginBox {
  margin-top: 3em;
}

.LoginButtonBox {
  margin-top: 3em;
}

.GetOtpBtn {
  background-color: #2a50ee;
  padding: '12px';
}

.StyleLink {
  color: #2a50ee !important;
}

input:-internal-autofill-selected {
  .MuiInputBase-root {
    background-color: -internal-light-dark(
      rgb(232, 240, 254),
      rgba(70, 90, 126, 0.4)
    ) !important;
  }
}

.AccountLockedBox {
  margin-top: 3em;
  max-width: 350px;
  text-align: left;
}

a {
  font-family: 'SF Pro Display';
}

.MuiButtonBase-root:disabled {
  background-color: rgba(42, 80, 238, 0.5) !important;
  color: #ffffff !important;
}

.MuiFormHelperText-root {
  margin-left: 0 !important;
}

.ErrorSpanStyle {
  margin-right: 5px;
  font-size: larger;
}

#password-helper-text,
#login-helper-text {
  max-width: 300px;
}

.ErrorTextSpan {
  color: #cc0000;
  font-size: medium !important;
  max-width: 350px !important;
}
