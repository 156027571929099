.otpContainer {
  margin: 5% auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
}

.otpBox {
  margin-top: 3em;
}

.otpInput {
  width: 45px !important;
  height: 60px;
  margin: 0 0.2rem;
  font-size: 2rem;
  text-align: center;
  border: 1px solid #09090a;
}

.InvalidOtpInput {
  width: 45px !important;
  height: 60px;
  margin: 0 0.2rem;
  font-size: 2rem;
  text-align: center;
  border: 1px solid #d11149;
  color: #d11149;
}

.OtpHeader {
  font-weight: 600 !important;
}

.otpInput:focus {
  border-color: #2a50ee !important;
}

/*  Add breakpoint for iPhone */
@media only screen and (max-width: 450px) {
  .otpInput {
    width: 1rem !important;
    height: 1.5rem;
    font-size: 1rem;
    padding: 8px;
  }
}
