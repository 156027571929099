.TotalAmount {
  display: flex;
  align-items: flex-start;
}

.AccordionStyle {
  background-color: #f7f7f7 !important;
  box-shadow: none !important;
  border-radius: 15px !important;
  margin-bottom: 2%;
}

.CardRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.RowStyle {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 2%;
}

.TotalAmountNumberStyle {
  font-size: 48px !important;
  font-weight: 400;
}

.AmountStyle {
  color: #7c919d;
  font-size: 14px !important;
}

.CurrencyStyle {
  font-size: 16px !important;
}
