.TransactionDetailsTabStyle {
  padding: 0 !important;
  text-transform: none !important;
  text-align: left !important;
  align-items: unset !important;
  font-weight: 500 !important;
  color: #36454F !important;
  margin-right: 1rem !important;
  min-width: 55px !important;
}

.ActiveTabStyle {
  padding: 0 !important;
  text-transform: none !important;
  text-align: left !important;
  align-items: unset !important;
  font-weight: 500 !important;
  color: #36454F !important;
  margin-right: 1rem !important;
  min-width: 55px !important;
  color: #2a50ee !important;
}

.TransactionDetailsTabsStyle {
  width: 100%;
}

.AccordionStyle {
  background-color: #f7f7f7 !important;
  box-shadow: none !important;
  border-radius: 15px !important;
  margin-bottom: 2%;
}

.AccordionStylePrint {
  background-color: #f7f7f7 !important;
  box-shadow: none !important;
  border-radius: 15px !important;
  margin-bottom: 50% !important;
}

.CardRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.CreditCards {
  box-shadow: none !important;
  border-radius: 10px;
  box-shadow: none;
  position: relative !important;
  width: 370px;
  // min-width: 200;
  // min-height: 360;
}

.ResponseCard {
  background-color: #f7f7f7 !important;
  box-shadow: none !important;
}

.ResponseImg {
  margin-top: 12%;
  height: 165;
  width: 165;
}

.LeftContent {
  position: absolute !important;
  z-index: 2 !important;
  bottom: 15%;
  left: 12%;
  width: '100%' !important;
}

.RightContent {
  position: absolute !important;
  z-index: 2 !important;
  bottom: 15%;
  right: 13%;
  width: '100%' !important;
}

.MiddleContent {
  position: absolute !important;
  z-index: 2 !important;
  top: 30%;
  left: 11.5%;
  width: 100% !important;
}

.SuccessIcon {
  font-size: 40px !important;
  color: #0e834d;
  width: 1.5em !important;
  height: 1.5em !important;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
}

.DeclinedIcon {
  font-size: 40px !important;
  width: 1.5em !important;
  height: 1.5em !important;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  color: #b50000;
}

.TotalAmountRow {
  display: flex;
  align-items: flex-start;
}

.PanNumber {
  letter-spacing: 8px;
  font-weight: 400;
  font-size: 20px;
  color: white;
}
