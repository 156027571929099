.AccordionStyle {
  background-color: #f7f7f7 !important;
  box-shadow: none !important;
  border-radius: 15px !important;
  margin-bottom: 2%;
}

.CardRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.UsersListPageStyle {
  padding-left: 1rem;
  padding-right: 1rem;
}

.SearchBox {
  background-color: white;
  width: 100%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 10px;
}

.SearchBox .MuiOutlinedInput-root {
  border-radius: 10px;
}


.UserTable {
  overflow: scroll;
}

.UserTableHeader {
  background-color: #f7f7f7 !important;
  color: #7c919d !important;
}

.EditButtonAlign {
  text-align: 'right';
}

.SideRailTitleStyle {
  text-transform: capitalize;
}
