.TransactionCheckContainer {
  padding: 1rem;
}

.TransactionCheckLabelStyle {
  color: #7c919d;
  padding: 5px;
  width: 100%;
  font-size: 12px !important;
}

.SectionBoxStyle {
  border-bottom: 1px solid #7c919d;
  padding-bottom: 10px;
  padding-top: 10px;
}

.AmountSectionBoxStyle {
  border-bottom: 1px solid #7c919d;
  padding-bottom: 20px;
  padding-top: 10px;
}

.ApprovedTextStyle {
  font-size: 36px !important;
  font-weight: 400 !important;
  color: #0e834d;
}

.DeclinedTextStyle {
  font-size: 36px !important;
  font-weight: 400 !important;
  color: #b50000;
}

.ApprovedIconStyle {
  font-size: 50px !important;
  margin-top: 5px;
  color: #0e834d;
}

.DeclinedIconStyle {
  font-size: 50px !important;
  margin-top: 5px;
  color: #b50000;
}
