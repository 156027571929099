.dropzone {
  text-align: center;
  padding: 20px;
  border: 1px #b4b7c3 dashed;
  width: 100%;
  margin: auto;
}
.btn {
  border: none;
  text-align: center;
  background-color: rgb(218, 216, 216);
  height: 50px;
  border-radius: 12px;
  color: #36454F;
  font-weight: bold;
  transition-duration: 0.6s;
}
.btn:hover {
  background-color: blue;
  color: aliceblue;
}
.file-list {
  /* border: 3px dotted #36454F; */
  display: flex !important;
  flex-wrap: wrap;
  width: auto;
  padding: 10px 20px;
  margin: 20px 30px;
  /* border: 3px dotted #36454F; */
}
.file-list img {
  height: 100%;
  width: 100px;
  padding-right: 10px;
  object-fit: cover;
}

.InnerContainer {
  background-color: #ffffff;
  box-shadow: 0px 8px 16px rgb(0 0 0 / 20%);
  padding: 1rem 2rem 2rem 2rem;
  position: relative;
  left: 6%;
}

.Files {
  display: flex;
  align-items: flex-start;
  gap: 3rem;
}
