

.MuiDrawer-paper {
  width: 55% !important;
  overflow: auto !important;
}


.SideRailHeaderStyle {
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px;
  box-sizing: border-box;
  padding: 15px 15px 0px 15px;
}

.SideRailBodyStyle {
  height: 100% !important;
  width: 100% !important;
  overflow: auto !important;
}

.SideRailFooterStyle {
  // position: fixed;
  bottom: 0;
  right: 0;
  height: 90px;
  background: #fff;
  box-shadow: 0px 2px 10px rgba(58, 89, 124, 0.2);
  z-index: 4;
  min-height: unset;
  width: 100%;
  overflow: auto !important;
}
