.CreditCardInputStyle {
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 10px !important;
  background: #ffffff;
}

.CreditCardContainerStyle {
  width: 52%;
}

.CreditCardStyle {
  width: 60%;

  fieldset {
    border: unset !important;
  }
}

.ExpiryDateStyle {
  width: 20%;
  fieldset {
    border: unset !important;
  }
}

.CvvStyle {
  width: 20%;
  fieldset {
    border: unset !important;
  }
}

.PaymentInfoBoxStyle {
  margin-top: 1rem;
  width: 100%;
}

.CreditCardErrorStyle {
  color: red;
}
