


.TrasactionFilterBox {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.DateSearch {
  width: 25%;
  color: white !important;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 10px !important;
}

.DateFilterMenu .MuiMenu-paper {
  width: auto !important;
}

.DateSearch .MuiOutlinedInput-root {
  border-radius: 10px;
}

.TransactionTable, .BatchTable {
  overflow: auto !important;
  height: calc(100vh - 320px) !important;
  width: 100%;  
}


.TransactionBatchTable {
  overflow: auto !important;
  height: 100% !important;
  width: 100% !important;
}


.TransactionTableHeader {
  background-color: #f7f7f7 !important;
  color: #7c919d !important;
}

.CheckboxCard {
  position: absolute;
  top: 92%;
  right: -52%;
  z-index: 99;
  padding: 1;
  background-color: white;
  // box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  box-shadow: 0 8px 6px -6px rgb(0 0 0 / 0.2);
  height: auto;
  width: 590px;
  display: flex;
  justify-content: flex-start;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.InnerBox {
  display: inline-block;
  margin-left: 6%;
  margin-top: 2%;
  margin-bottom: 5%;
}

.MiddleBox {
  margin-left: 10%;
  margin-top: 2%;
  margin-bottom: 5%;
}

.RightBox {
  margin-left: 1%;
  margin-bottom: 5%;
}

.CheckBoxGroup {
  height: 28px;
}

.CheckBox {
  color: rgba(42, 80, 238, 0.5) !important;
}

.CheckBox.Mui-checked {
  color: #2a50ee !important;
}

.SideRailfooterButtonStyle {
  border-radius: 10px !important;
  margin-right: 1rem !important;
}

.SubTransactionButtonsStyle {
  text-align: end !important;
  justify-content: end !important;
  width: 100% !important;
}

.TransactionDetailsTabStyle {
  color: #2a50ee !important;
}

.ReportingPrintButtonStyle {
  border-radius: 10px !important;
  text-transform: uppercase !important;
}

.ReportingTabFullWidth {
  width: 100%;
}

.ReportingTabHeader {
  font-size: x-large !important;
  font-weight: bold !important;
}

.PaddingDetailsPage {
  padding: 15px !important;
}

.BatchDetailsIdStyle {
  font-size: medium;
  margin-top: x-small;
}

.CircularBarStyle {
  margin-left: 45%;
  size: 24;
}

.DownlaodBatchButtonsStyle {
  width: 50% !important;
}

.BatchCloseSpinnerStyle {
  margin-right: 2%;
}
