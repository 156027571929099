.CountDownTimerBoxStyle {
  margin-top: 3%;
  flex-direction: row;
  display: flex;
}
.BottomActionBarStyle {
  align-content: center;
  justify-content: center !important;
}
.TimeBoxStyle {
  flex-direction: row;
  display: flex;
  margin-left: 1%;
}

.UserActivityContainerStyle {
  border-radius: 10px;
}
