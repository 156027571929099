.PrincipleRow {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  height: 5.5rem;
}

.SectionBox {
  padding: 15px !important;
}

.FirstNameStyle {
  width: 97% !important;
  background: #ffffff;
}

.LastNameStyle {
  content: '%' !important;
  width: 97% !important;
  margin-left: 3% !important;
  margin-right: 4% !important;
  background: #ffffff;
}

.ssnNameStyle {
  width: 97% !important;
  margin-left: 3% !important;
  margin-right: 4% !important;
  background: #ffffff;
}

.PrincipleRoleSection {
  width: 200% !important;
}

.RemoveButtonText {
  color: #ef6f76 !important;
  text-transform: none !important;
  font-size: x-small !important;
  height: 30px !important;
}

.RemoveIconText {
  font-size: 10px !important;
}
