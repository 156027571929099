.offlineStatusIcon {
  font-size: 4rem !important;
}

.offlineBoxStyle {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 2rem;
}
