.MerchantTableRowStyle {
  cursor: pointer;
}

.MerchantTableHeader {
  background-color: #f7f7f7 !important;
  color: #7c919d !important;
  width: 100rem;
}

.DisplayNone {
  display: none !important;
}
