.ChildBoxStyle {
  padding: 10px 0px 10px 0px;
}

.ChildBoxWithNoPadding {
  padding: 0;
}

.ChildBoxTitle {
  font-size: 14px !important;
  color: #7c919d;
}

.SettingRowMainStyle {
  width: 100%;
  height: auto;
}
