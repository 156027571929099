.FirstNameStyle {
  width: 97% !important;
  background: #ffffff;
}

.UserRow {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  height: 6.2rem;
}

.SectionBox {
  padding: 15px !important;
}

.LastNameStyle {
  width: 97% !important;
  margin-left: 3% !important;
  margin-right: 4% !important;
  background: #ffffff;
}

.SecondColoumnStyle {
  background: #ffffff;
}

.HeadingText {
  width: 100% !important;
  margin-bottom: 0.5rem !important;
}

.SearchFieldLabelStyle {
  margin-top: 4% !important;
  width: 93% !important;
  margin-left: 4% !important;
  padding: 0px !important;
}

.SelectFormControlStyle {
  margin-right: 3% !important;
}

.SubHeadingText {
  width: 100% !important;
  margin-bottom: 2rem !important;
  font-weight: 500 !important;
  color: #7c919d;
}

.StyledAddAddressButton {
  width: 100% !important;
  display: flex;
  justify-content: flex-end;
  height: 30px !important;
}

.AddressSection {
  width: 100% !important;
  display: flex;
}

.FullWidth {
  width: 100% !important;
  margin-bottom: 1rem !important;
}

.AddressSecondColoumn {
  margin-left: 3% !important;
}
