.MerchantApiTabContainer {
  padding-top: 3%;
  padding-left: 2%;
  padding-right: 2%;
}

.ApiTabBoxStyle {
  background-color: #f7f7f7;
  border-radius: 20px;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 5%;
  padding-top: 3%;
}

.APITabLabelStyle {
  font-weight: bold !important;
}

.ApikeyContentBoxStyle {
  margin-top: 20px;
}

.StatusContentBoxStyle {
  margin-top: 20px;
  width: 50%;
}
