

.MerchantFilterBox {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.MerchantTable {
  overflow: scroll;
}

.MerchantRailTitle {
  width: 100%;
}

.MerchantFormField {
  width: 100%;
}



.LastRow {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}
